import { useEffect, useState } from "react"

export function useThrottledState<Type>(initialValue: Type, delay: number = 600) {
  const [value, setValue] = useState<Type>(initialValue)
  const [throttledValue, setThrottledValue] = useState<Type>(initialValue)

  useEffect(() => {
    const timeout = setTimeout(() => {
      setThrottledValue(value)
    }, delay)

    return () => {
      clearTimeout(timeout)
    }
  }, [value, delay])

  return [throttledValue, value, setValue] as const
}
