// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Tooltip_tooltip__tIirw {
  background-color: rgba(0, 0, 0, 0.8) !important;
  border-radius: 5px !important;
  border: 1px solid rgba(0, 0, 0, 0.5) !important;
  padding: 8px !important;
  box-shadow: rgba(100, 100, 111, 0.2) 0 7px 19px 0 !important;
  white-space: pre-wrap;
}

.Tooltip_tooltipText__FfdIC {
  color: #ffffff;
}`, "",{"version":3,"sources":["webpack://./src/components/Tooltip/Tooltip.module.scss"],"names":[],"mappings":"AAAA;EACE,+CAAA;EACA,6BAAA;EACA,+CAAA;EACA,uBAAA;EACA,4DAAA;EACA,qBAAA;AACF;;AAEA;EACE,cAAA;AACF","sourcesContent":[".tooltip {\n  background-color: rgba(0, 0, 0, 0.8) !important;\n  border-radius: 5px !important;\n  border: 1px solid rgba(0, 0, 0, 0.5) !important;\n  padding: 8px !important;\n  box-shadow: rgb(100 100 111 / 20%) 0 7px 19px 0 !important;\n  white-space: pre-wrap;\n}\n\n.tooltipText {\n  color: #ffffff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tooltip": `Tooltip_tooltip__tIirw`,
	"tooltipText": `Tooltip_tooltipText__FfdIC`
};
export default ___CSS_LOADER_EXPORT___;
