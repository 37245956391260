import { useEffect, useState } from "react"
import { useSelector } from "react-redux"

import { authApi } from "api/auth"
import request from "api/request"
import { getToken } from "api/request/request"
import { dispatch, RootState } from "services/store"
import { plexAuth } from "views/PlexAuth/authUtils"

export function useAuthenticate() {
  const [isLogging, setIsLogging] = useState(false)

  const { me, token = getToken() } = useSelector((state: RootState) => state.me)

  useEffect(() => {
    if (!me && token) {
      request.auth.getMe().catch(() => localStorage.removeItem("token"))
    } else if (!token) {
      const storageToken = getToken()
      if (!storageToken) request.auth.logout()
      else dispatch(authApi.actions.login(storageToken))
    }
  }, [token, me])

  async function authenticate() {
    setIsLogging(true)
    try {
      const plexAuthToken = await plexAuth()
      await request.auth.login(plexAuthToken)
    } catch (error) {
      console.error(error)
    } finally {
      setIsLogging(false)
    }
  }

  return {
    isLoggedIn: !!token,
    isLogging,
    authenticate,
    me,
  }
}
