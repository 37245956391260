/* eslint-disable no-console */
import { useEffect, useState } from "react"

import { mediasApi } from "api/medias"
import { socket, socketNS } from "services/sockets"
import { dispatch } from "services/store"
import { Media } from "types/Content"

export function useDownloads() {
  const [downloads, setDownloads] = useState<Record<string, Media<any>>>({})

  useEffect(() => {
    console.log("Socket init !")
    socket.on(socketNS.RDTC_DL, (data: Record<string, Media<any>>) => {
      setDownloads(
        Object.values(data)
          .filter((media) => media.downloadStatus)
          .reduce((acc, media) => ({ ...acc, [media.tmdbId]: media }), {}),
      )
      Object.values(data).forEach((media) => {
        dispatch(mediasApi.actions.add(media))
      })
    })

    socket.connect()

    return () => {
      console.log("Socket deinit !")
      socket.off(socketNS.RDTC_DL_RECEPT)
      socket.disconnect()
    }
  }, [])

  return { downloads }
}
