import { createApi } from "@reduxjs/toolkit/query/react"

import { User } from "types/User"

import { baseQuery } from "./baseQuery"
import { createCRUDEndpoints } from "./createCRUDEndpoints"

const usersApi = createApi({
  reducerPath: "users",
  baseQuery: baseQuery,
  tagTypes: ["User"],
  endpoints: (build) => ({
    ...(() => {
      const endpoints = createCRUDEndpoints<typeof build, User>(build, "users", "User")
      return {
        getUser: endpoints.get,
        getAllUser: endpoints.getAll,
        deleteUser: endpoints.delete,
        updateUser: endpoints.update,
      }
    })(),
  }),
})

export { usersApi }
