import { createSlice, PayloadAction } from "@reduxjs/toolkit"

import { Content } from "types/Content"

type Media = Content<"movie"> | Content<"serie">

export type MediasState = {
  isLoading: boolean
  medias: Record<Media["tmdbId"], Media>
}

const initialState = {
  isLoading: false,
  medias: {},
} as MediasState

export const mediasApi = createSlice({
  name: "medias",
  initialState,
  reducers: {
    add: (state, action: PayloadAction<Media>) => {
      if (!action.payload.tmdbId) return
      state.medias[action.payload.tmdbId] = action.payload
    },
    addList: (state, action: PayloadAction<Media[]>) => {
      action.payload.forEach((media) => {
        if (!media.tmdbId) return
        state.medias[media.tmdbId] = media
      })
    },
    remove: (state, action: PayloadAction<string>) => {
      delete state.medias[action.payload]
    },
    clear: (state) => {
      state.medias = {}
    },
  },
})

export default mediasApi.reducer
