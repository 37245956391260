import { io } from "socket.io-client"

import { getToken } from "api/request/request"

import { WS_URL } from "../constants"

export type SocketNamesapce = "rdtc_downloads"

export const socketNS = {
  RDTC_DL: "downloads",
  RDTC_DL_RECEPT: "RDTC_DL_RECEPT",
} as const

export const socket = io(WS_URL, {
  autoConnect: true,
  reconnection: true,

  query: {
    token: getToken(),
  },
})
