// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MediaDetail_container__isbur {
  display: flex;
  flex-direction: column;
  font-size: 17px;
}

.MediaDetail_background__1fZld {
  position: absolute;
  opacity: 0.2;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  pointer-events: none;
}

.MediaDetail_title__jABjf {
  position: relative;
  z-index: 1;
  width: 100%;
}

.MediaDetail_mediaInfos__0aFMI {
  z-index: 1;
  display: flex;
  flex-direction: row;
  height: 100%;
  position: sticky;
  top: 10px;
}

.MediaDetail_image__mk65T {
  max-width: 250px;
  border-radius: 6px;
}

.MediaDetail_rating__QnkVM {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.MediaDetail_infos__fHPRP {
  margin-left: 20px;
}

.MediaDetail_field__j4Wj5 {
  margin-bottom: 20px;
}`, "",{"version":3,"sources":["webpack://./src/components/Media/MediaDetail.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,eAAA;AACF;;AAEA;EACE,kBAAA;EACA,YAAA;EACA,MAAA;EACA,OAAA;EACA,QAAA;EACA,SAAA;EACA,sBAAA;EACA,oBAAA;AACF;;AAEA;EACE,kBAAA;EACA,UAAA;EACA,WAAA;AACF;;AAEA;EACE,UAAA;EACA,aAAA;EACA,mBAAA;EACA,YAAA;EACA,gBAAA;EACA,SAAA;AACF;;AAEA;EACE,gBAAA;EACA,kBAAA;AACF;;AAEA;EACE,WAAA;EACA,aAAA;EACA,uBAAA;EACA,gBAAA;AACF;;AAEA;EACE,iBAAA;AACF;;AAEA;EACE,mBAAA;AACF","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  font-size: 17px;\n}\n\n.background {\n  position: absolute;\n  opacity: 0.2;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  background-size: cover;\n  pointer-events: none;\n}\n\n.title {\n  position: relative;\n  z-index: 1;\n  width: 100%;\n}\n\n.mediaInfos {\n  z-index: 1;\n  display: flex;\n  flex-direction: row;\n  height: 100%;\n  position: sticky;\n  top: 10px;\n}\n\n.image {\n  max-width: 250px;\n  border-radius: 6px;\n}\n\n.rating {\n  width: 100%;\n  display: flex;\n  justify-content: center;\n  margin-top: 20px;\n}\n\n.infos {\n  margin-left: 20px;\n}\n\n.field {\n  margin-bottom: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `MediaDetail_container__isbur`,
	"background": `MediaDetail_background__1fZld`,
	"title": `MediaDetail_title__jABjf`,
	"mediaInfos": `MediaDetail_mediaInfos__0aFMI`,
	"image": `MediaDetail_image__mk65T`,
	"rating": `MediaDetail_rating__QnkVM`,
	"infos": `MediaDetail_infos__fHPRP`,
	"field": `MediaDetail_field__j4Wj5`
};
export default ___CSS_LOADER_EXPORT___;
