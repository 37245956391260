// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Search_discover__1nUJK {
  display: flex;
  flex-direction: column;
}
.Search_discover__1nUJK > :not(:first-child) {
  margin-top: 40px;
}`, "",{"version":3,"sources":["webpack://./src/views/Search/Search.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;AACF;AAAE;EACE,gBAAA;AAEJ","sourcesContent":[".discover {\n  display: flex;\n  flex-direction: column;\n  > :not(:first-child) {\n    margin-top: 40px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"discover": `Search_discover__1nUJK`
};
export default ___CSS_LOADER_EXPORT___;
