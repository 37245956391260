import { ReactNode } from "react"
import YouTube from "react-youtube"

import { FontIcon, IconName } from "components/FontIcon/FontIcon"

import classes from "./Field.module.scss"

type FieldProps<F extends "short" | "long" | "youtube" = "short"> = {
  name: string
  value: F extends "youtube" ? string : ReactNode
  icon?: IconName
  format?: F
  className?: string
  style?: React.CSSProperties
  iconStyle?: React.CSSProperties
}

export function Field<F extends "short" | "long" | "youtube" = "short">(props: FieldProps<F>) {
  const { name, value, icon, format = "short", className, style, iconStyle } = props

  if (!value) return null

  return (
    <div className={`${classes.container} ${className || ""}`} style={style}>
      {icon && (
        <div>
          <FontIcon icon={icon} className={classes.icon} style={iconStyle} />
        </div>
      )}
      {format === "youtube" && typeof value === "string" ? (
        <YouTube
          videoId={value}
          opts={{
            height: 230,
            width: 410,
          }}
        />
      ) : (
        <div>
          {format === "long" ? <div className={classes.key}>{name}</div> : null}
          <div className={classes.value}>{value}</div>
        </div>
      )}
    </div>
  )
}
