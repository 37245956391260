import { FontIcon } from "components/FontIcon/FontIcon"
import { DownloadStatus } from "types/Content"

import classes from "./DownloadProgress.module.scss"

type DownloadProgressProps = {
  download: DownloadStatus
  style?: React.CSSProperties
  className?: string
  format?: "full" | "compact"
}

export function DownloadProgress(props: DownloadProgressProps) {
  const { style, className, download, format = "full" } = props
  const { progress, error, status } = download

  return (
    <div
      className={`${classes.container} ${className || ""}`}
      style={style}
      size-format={format}
      has-error={String(!!(Array.isArray(error) && error.length) || typeof error === "string")}
    >
      <div
        className={classes.progressBar}
        style={{
          width: `${progress}%`,
          backgroundColor:
            status === "importPending"
              ? "#b97a23"
              : status === "downloading"
                ? "#546abc"
                : status === "downloaded"
                  ? "#4CAF50"
                  : "#FFC107",
        }}
      />
      {(Array.isArray(error) && error.length) || typeof error === "string" ? (
        <div className={classes.speed}>
          {typeof error === "string" ? (
            <FontIcon icon="warning" />
          ) : Array.isArray(error) && status === "importPending" ? (
            format === "compact" ? (
              <FontIcon icon="warning" />
            ) : (
              "Import manuel requis"
            )
          ) : format === "compact" ? (
            <FontIcon icon="info" />
          ) : (
            "Inconnue"
          )}
        </div>
      ) : (
        <div className={classes.speed}>{`${progress}%`}</div>
      )}
    </div>
  )
}
