import { forEach } from "lodash"
import isPlainObject from "lodash/isPlainObject"
import mapValues from "lodash/mapValues"
import pickBy from "lodash/pickBy"

import { ReadAllParams } from "types/API"

export function getApiURI(): string {
  const { REACT_APP_API_URL, REACT_APP_API_PORT, REACT_APP_API_ROOT } = process.env

  if (!REACT_APP_API_URL || !REACT_APP_API_ROOT) {
    throw new Error(
      "Environement variables were not set during the application compilation. Don't forget to setup the .env file with the correct endpoints.",
    )
  }

  const apiURI = `${REACT_APP_API_URL}${
    REACT_APP_API_PORT ? `:${REACT_APP_API_PORT}` : ""
  }${REACT_APP_API_ROOT ? `${REACT_APP_API_ROOT}` : "/"}`
  return apiURI
}

const cleanObject = (obj: any): any => pickBy(obj, (v) => v !== undefined)

const replaceKeyObject =
  <T>(prevKey: string, nextKey: string) =>
  (obj: any): T => {
    if (!isPlainObject(obj)) return obj

    const remappedObject = {
      ...mapValues(obj, (value) => {
        if (isPlainObject(value)) {
          return replaceKeyObject(prevKey, nextKey)(value)
        }
        if (Array.isArray(value)) {
          return value.map(replaceKeyObject(prevKey, nextKey))
        }

        return value
      }),
      [nextKey]: obj[prevKey],
      [prevKey]: undefined,
    }

    return cleanObject(remappedObject)
  }

export function filterResponse<Item extends Record<string, any>>(
  response: Item[],
  filters?: ReadAllParams["filters"],
): Item[] {
  if (!filters) return response.map((_) => _)

  if (typeof filters === "function") return filters<Item>(response)

  if (typeof filters === "object") {
    const results: Item[] = []
    response.forEach((item) => {
      let push = true
      forEach(filters, (value, key) => {
        if (item[key] !== value) push = false
      })
      if (push) results.push(item)
    })
    return results
  }

  return response
}

export const replacekey =
  <T>(prevKey: string, nextKey: string) =>
  (data: T): any => {
    if (Array.isArray(data)) return data.map(replaceKeyObject<T>(prevKey, nextKey))
    return replaceKeyObject<T>(prevKey, nextKey)(data)
  }

export const idTo_id = <T>(data: T): T => replacekey<T>("id", "_id")(data)
export const _idToId = <T>(data: T): T => replacekey<T>("_id", "id")(data)
export const tmdbIdToId = <T>(data: T): T => replacekey<T>("tmdbId", "id")(data)

export function transformRequest<Item>(data: Item) {
  if (typeof data === "object") return idTo_id<Item>(data)
  return data
}

export function transformResponse<Item>(data: Item) {
  return _idToId<Item>(data)
}
