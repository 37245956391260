// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rs-modal-wrapper {
  padding: 20px !important;
  background-color: rgba(0, 0, 0, 0.8);
}

.rs-modal,
.rs-modal-content {
  border-radius: 4px !important;
}

.rs-modal-header-close {
  width: 25px !important;
  height: 25px !important;
  border-radius: 4px !important;
  background-color: rgba(255, 0, 0, 0.3) !important;
}
.rs-modal-header-close:hover {
  background-color: rgba(255, 0, 0, 0.6) !important;
}

.rs-modal-body {
  position: unset;
}

.rs-tag {
  position: relative;
  z-index: 10;
}

.rs-table-cell {
  max-width: 100%;
}

.rs-table-cell-content {
  display: flex;
  align-items: center;
}

.rs-table-cell-wrap {
  max-width: 100%;
  min-width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/styles/rs-default-theme.scss"],"names":[],"mappings":"AAAA;EACE,wBAAA;EACA,oCAAA;AACF;;AAEA;;EAEE,6BAAA;AACF;;AAEA;EACE,sBAAA;EACA,uBAAA;EACA,6BAAA;EACA,iDAAA;AACF;AAAE;EACE,iDAAA;AAEJ;;AAEA;EACE,eAAA;AACF;;AAEA;EACE,kBAAA;EACA,WAAA;AACF;;AAEA;EACE,eAAA;AACF;;AACA;EACE,aAAA;EACA,mBAAA;AAEF;;AACA;EACE,eAAA;EACA,eAAA;AAEF","sourcesContent":[".rs-modal-wrapper {\n  padding: 20px !important;\n  background-color: rgba(0, 0, 0, 0.8);\n}\n\n.rs-modal,\n.rs-modal-content {\n  border-radius: 4px !important;\n}\n\n.rs-modal-header-close {\n  width: 25px !important;\n  height: 25px !important;\n  border-radius: 4px !important;\n  background-color: rgba(255, 0, 0, 0.3) !important;\n  &:hover {\n    background-color: rgba(255, 0, 0, 0.6) !important ;\n  }\n}\n\n.rs-modal-body {\n  position: unset;\n}\n\n.rs-tag {\n  position: relative;\n  z-index: 10;\n}\n\n.rs-table-cell {\n  max-width: 100%;\n}\n.rs-table-cell-content {\n  display: flex;\n  align-items: center;\n}\n\n.rs-table-cell-wrap {\n  max-width: 100%;\n  min-width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
