// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.twTable .rs-table-cell[role=columnheader] {
  font-size: 15px;
  font-weight: 600;
}
.twTable .rs-table-cell[role=columnheader] .rs-table-cell-content {
  padding: 0 20px !important;
  display: flex;
  align-items: center;
}
.twTable .rs-table-cell[role=columnheader].rs-table-cell-first {
  border-top-left-radius: 10px !important;
}
.twTable .rs-table-cell[role=columnheader].rs-table-cell-last {
  border-top-right-radius: 10px !important;
}
.twTable .rs-table-cell-full-text:hover {
  box-shadow: none !important;
}`, "",{"version":3,"sources":["webpack://./src/components/Table/Table.scss"],"names":[],"mappings":"AAEI;EACE,eAAA;EACA,gBAAA;AADN;AAGM;EACE,0BAAA;EACA,aAAA;EACA,mBAAA;AADR;AAIM;EACE,uCAAA;AAFR;AAMM;EACE,wCAAA;AAJR;AAUE;EACE,2BAAA;AARJ","sourcesContent":[".twTable {\n  .rs-table-cell {\n    &[role=\"columnheader\"] {\n      font-size: 15px;\n      font-weight: 600;\n\n      .rs-table-cell-content {\n        padding: 0 20px !important;\n        display: flex;\n        align-items: center;\n      }\n\n      &.rs-table-cell-first {\n        border-top-left-radius: 10px !important;\n        // border-bottom-left-radius: 10px !important;\n      }\n\n      &.rs-table-cell-last {\n        border-top-right-radius: 10px !important;\n        // border-bottom-right-radius: 10px !important;\n      }\n    }\n  }\n\n  .rs-table-cell-full-text:hover {\n    box-shadow: none !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
