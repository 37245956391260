// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MediaList_container__HRKST {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100%;
}
.MediaList_container__HRKST *::-webkit-scrollbar {
  background-color: transparent;
  width: 12px;
  height: 12px;
  z-index: 999999;
}
.MediaList_container__HRKST *::-webkit-scrollbar-track {
  background-color: transparent;
}
.MediaList_container__HRKST *::-webkit-scrollbar-thumb {
  background-color: transparent;
  border-radius: 12px;
  border: 0 solid var(--rs-bg-overlay);
}
.MediaList_container__HRKST *::-webkit-scrollbar-button {
  display: none;
}
.MediaList_container__HRKST *:hover::-webkit-scrollbar-thumb {
  background-color: var(--rs-gray-400);
  border: 2px solid var(--rs-bg-overlay);
}
.MediaList_container__HRKST *::-webkit-scrollbar-thumb:hover {
  background-color: var(--rs-gray-400);
  border: 2px solid var(--rs-gray-400);
}

.MediaList_mediasContainer__ZsoPg {
  overflow-x: auto;
  width: 100%;
  max-width: 100%;
  height: fit-content;
}

.MediaList_medias__8Iskc {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  height: 320px;
}
.MediaList_medias__8Iskc > :not(:first-child) {
  margin-left: 10px;
}

.MediaList_mediaPlaceholder__HS89H {
  min-width: 200px;
  min-height: 300px;
  margin: 5px;
  border-radius: 5px;
  padding: 5px;
}`, "",{"version":3,"sources":["webpack://./src/components/MediaList/MediaList.module.scss","webpack://./src/styles/_mixin.scss"],"names":[],"mappings":"AAEA;EAGE,aAAA;EACA,sBAAA;EACA,WAAA;EACA,eAAA;AAHF;ACCE;EACE,6BAAA;EACA,WALM;EAMN,YAAA;EACA,eAAA;ADCJ;ACEE;EACE,6BAAA;ADAJ;ACGE;EACE,6BAAA;EACA,mBAAA;EACA,oCAAA;ADDJ;ACIE;EACE,aAAA;ADFJ;ACKE;EACE,oCA3BM;EA4BN,sCAAA;ADHJ;ACME;EACE,oCAhCM;EAiCN,oCAAA;ADJJ;;AAnBA;EACE,gBAAA;EACA,WAAA;EACA,eAAA;EACA,mBAAA;AAsBF;;AAnBA;EACE,aAAA;EACA,iBAAA;EACA,mBAAA;EACA,aAAA;AAsBF;AApBE;EACE,iBAAA;AAsBJ;;AAlBA;EACE,gBAAA;EACA,iBAAA;EACA,WAAA;EACA,kBAAA;EACA,YAAA;AAqBF","sourcesContent":["@import \"/src/styles/mixin.scss\";\n\n.container {\n  @include scrollbar();\n\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  max-width: 100%;\n}\n\n.mediasContainer {\n  overflow-x: auto;\n  width: 100%;\n  max-width: 100%;\n  height: fit-content;\n}\n\n.medias {\n  display: flex;\n  flex-wrap: nowrap;\n  flex-direction: row;\n  height: 320px;\n\n  & > :not(:first-child) {\n    margin-left: 10px;\n  }\n}\n\n.mediaPlaceholder {\n  min-width: 200px;\n  min-height: 300px;\n  margin: 5px;\n  border-radius: 5px;\n  padding: 5px;\n}\n","@mixin scrollbar(\n  $color: var(--rs-gray-400),\n  $background: var(--rs-bg-overlay),\n  $width: 12px,\n  $hide: true\n) {\n  *::-webkit-scrollbar {\n    background-color: transparent;\n    width: $width;\n    height: 12px;\n    z-index: 999999;\n  }\n\n  *::-webkit-scrollbar-track {\n    background-color: transparent;\n  }\n\n  *::-webkit-scrollbar-thumb {\n    background-color: transparent;\n    border-radius: 12px;\n    border: 0 solid $background;\n  }\n\n  *::-webkit-scrollbar-button {\n    display: none;\n  }\n\n  *:hover::-webkit-scrollbar-thumb {\n    background-color: $color;\n    border: 2px solid $background;\n  }\n\n  *::-webkit-scrollbar-thumb:hover {\n    background-color: $color;\n    border: 2px solid $color;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `MediaList_container__HRKST`,
	"mediasContainer": `MediaList_mediasContainer__ZsoPg`,
	"medias": `MediaList_medias__8Iskc`,
	"mediaPlaceholder": `MediaList_mediaPlaceholder__HS89H`
};
export default ___CSS_LOADER_EXPORT___;
