// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PonponMenu_container__3gP6d {
  position: fixed;
  left: 0;
  top: 8px;
  width: 100vw;
  height: 47px;
  padding: 0 270px 0 55px;
  -webkit-user-select: none;
          user-select: none;
  pointer-events: none;
}

.PonponMenu_menuBar__q6wHi {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  background-color: rgb(10, 10, 10);
  pointer-events: all;
}`, "",{"version":3,"sources":["webpack://./src/PonponMenu.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,OAAA;EACA,QAAA;EACA,YAAA;EACA,YAAA;EACA,uBAAA;EACA,yBAAA;UAAA,iBAAA;EACA,oBAAA;AACF;;AAEA;EACE,WAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,iBAAA;EACA,8BAAA;EACA,mBAAA;EACA,iCAAA;EACA,mBAAA;AACF","sourcesContent":[".container {\n  position: fixed;\n  left: 0;\n  top: 8px;\n  width: 100vw;\n  height: 47px;\n  padding: 0 270px 0 55px;\n  user-select: none;\n  pointer-events: none;\n}\n\n.menuBar {\n  width: 100%;\n  height: 100%;\n  display: flex;\n  flex-direction: row;\n  flex-wrap: nowrap;\n  justify-content: space-between;\n  align-items: center;\n  background-color: rgb(10 10 10);\n  pointer-events: all;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `PonponMenu_container__3gP6d`,
	"menuBar": `PonponMenu_menuBar__q6wHi`
};
export default ___CSS_LOADER_EXPORT___;
