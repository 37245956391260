import { createSlice, PayloadAction } from "@reduxjs/toolkit"

import { User } from "types/User"

export type AuthState = {
  me: User | null
  token: string | null
}

const initialState = {
  me: null,
  token: null,
} as AuthState

export const authApi = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login: (state, action: PayloadAction<string>) => {
      state.token = action.payload
    },
    logout: (state) => {
      state.me = null
      state.token = null
    },
    getMe: (state, action: PayloadAction<User>) => {
      state.me = action.payload
    },
  },
})

export default authApi.reducer
