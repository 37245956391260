import { ForwardedRef, forwardRef, Fragment, ReactNode } from "react"
import { Button as RSButton, ButtonProps as RSButtonProps } from "rsuite"

import { FontIcon, FontIconProps, IconName } from "components/FontIcon/FontIcon"
import { Tooltip } from "components/Tooltip/Tooltip"

import classes from "./Button.module.scss"

export type ButtonProps = Omit<RSButtonProps, "color"> & {
  ref?: ForwardedRef<HTMLButtonElement>
  icon?: IconName
  tooltip?: ReactNode
  iconProps?: Partial<FontIconProps>
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>((props: ButtonProps, ref) => {
  const { children, appearance, disabled, icon, loading, tooltip, iconProps, ...buttonProps } =
    props

  const Root = tooltip
    ? (props: any) => <Tooltip content={tooltip} {...props} />
    : (props: any) => <Fragment {...props} />

  return (
    <Root>
      <RSButton appearance={appearance} disabled={disabled || loading} {...buttonProps} ref={ref}>
        {icon || loading ? (
          <FontIcon
            icon={loading ? "spinner" : (icon as IconName)}
            style={children ? { marginRight: 10 } : undefined}
            className={classes.icon}
            spin={loading}
            {...iconProps}
          />
        ) : null}
        {children}
      </RSButton>
    </Root>
  )
})
