const imagesSizes = {
  backdrop: ["w300", "w780", "w1280", "original"],
  logo: ["w45", "w92", "w154", "w185", "w300", "w500", "original"],
  poster: ["w92", "w154", "w185", "w342", "w500", "w780", "original"],
  profile: ["w45", "w185", "h632", "original"],
  still: ["w92", "w185", "w300", "original"],
} as const

type ImageSizes = typeof imagesSizes

export function TMDBImage<K extends keyof ImageSizes | void>(
  props: {
    src?: string | null
    type: K
    size?: K extends keyof ImageSizes ? ImageSizes[K][number] : never
    className?: string
    style?: React.CSSProperties
  } & Partial<typeof HTMLImageElement>,
) {
  const { src, type, size = "original", ...imgProps } = props

  if (!src) return null

  return (
    <img src={`https://image.tmdb.org/t/p/${size}/${src}`} alt={`tmdb-${type}`} {...imgProps} />
  )
}
