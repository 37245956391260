import { Message, toaster } from "rsuite"

type MessageProps = {
  text: string
  header?: string
  type?: "success" | "warning" | "error" | "info"
  onClose?: () => void
}

export function Notification(props: MessageProps) {
  const { text, type = "info", header, ...rest } = props

  return (
    <Message type={type} showIcon closable header={header} {...rest}>
      {text}
    </Message>
  )
}

export const notify = {
  success: (text: string, duration = 2000) => {
    toaster.push(<Notification text={text} type="success" />, {
      duration,
      placement: "topEnd",
    }) as string
  },
  warning: (text: string, duration = 2000) => {
    toaster.push(<Notification text={text} type="warning" />, {
      duration,
      placement: "topEnd",
    }) as string
  },
  error: (text: string, header?: string, duration = 2000) => {
    toaster.push(<Notification header={header} text={text} type="error" />, {
      duration,
      placement: "topEnd",
    }) as string
  },
  info: (text: string, duration = 2000) => {
    toaster.push(<Notification text={text} type="info" />, {
      duration,
      placement: "topEnd",
    }) as string
  },
}
