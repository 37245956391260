import { RadarrMovie } from "types/Arr"

import { RottenTomatoesRatingLogos } from "../RottenTomatoesRatingLogos/RottenTomatoesRatingLogos"

import classes from "./MultiRating.module.scss"

type MultiRatingProps = {
  ratings: Partial<RadarrMovie["ratings"]>
  style?: React.CSSProperties
  className?: string
}

export function MultiRating(props: MultiRatingProps) {
  const { ratings, style, className } = props
  const { imdb, tmdb, rottenTomatoes, trakt } = ratings

  return (
    <div className={`${classes.container} ${className || ""}`} style={style}>
      {imdb?.value ? (
        <div className={classes.rating}>
          <div className={classes.logo}>
            <img src="/images/imdb_logo.png" />
          </div>
          <div className={classes.ratingVotes}>{imdb.value.toFixed(1)}</div>
        </div>
      ) : null}
      {tmdb?.value ? (
        <div className={classes.rating}>
          <div className={classes.logo}>
            <img src="/images/tmdb_logo.svg" />
          </div>
          <div className={classes.ratingValue}>{tmdb.value.toFixed(1)}</div>
        </div>
      ) : null}

      {rottenTomatoes?.value ? (
        <div className={classes.rating}>
          <div className={classes.logo}>
            <RottenTomatoesRatingLogos rating={Number(rottenTomatoes.value.toFixed(1))} />
          </div>
          <div className={classes.ratingValue}>{rottenTomatoes.value}%</div>
        </div>
      ) : null}
      {trakt?.value ? (
        <div className={classes.rating}>
          <div className={classes.logo}>
            <img src="/images/trakt_logo.svg" />
          </div>
          <div className={classes.ratingValue}>{trakt.value.toFixed(1)}</div>
        </div>
      ) : null}
    </div>
  )
}
