import { IconName } from "@fortawesome/fontawesome-svg-core"
import { CSSProperties, MouseEvent, MouseEventHandler, useMemo, useState } from "react"

import request from "api/request"
import { Button } from "components/Button/Button"
import { DownloadProgress } from "components/DownloadProgress/DownloadProgress"
import { Media, MediaType } from "types/Content"

import classes from "./ImportButton.module.scss"

export function ImportButton<T extends MediaType>(props: {
  media: Media<T>
  format?: "full" | "compact"
  style?: CSSProperties
}) {
  const { media, style, format = "full" } = props
  const { status, downloadStatus, lastRequestedAt } = media || {}

  const [isImporting, setIsImporting] = useState(false)

  async function onImport(e: MouseEvent) {
    e.nativeEvent.stopImmediatePropagation()
    e.nativeEvent.stopPropagation()
    e.stopPropagation()
    e.preventDefault()
    setIsImporting(true)
    try {
      await request.medias.import(media)
    } catch (error) {
      console.error(error)
    }
    setIsImporting(false)
  }

  async function onRestartImport(e: MouseEvent) {
    e.nativeEvent.stopImmediatePropagation()
    e.nativeEvent.stopPropagation()
    e.stopPropagation()
    e.preventDefault()
    setIsImporting(true)
    try {
      await request.medias.restartImport(media)
    } catch (error) {
      console.error(error)
    }
    setIsImporting(false)
  }

  const alreadyRequested = useMemo(() => {
    return lastRequestedAt
      ? new Date(lastRequestedAt).getTime() > Date.now() - 1000 * 60 * 60 * 24
      : false
  }, [lastRequestedAt])

  const action = useMemo((): {
    text: string
    icon: IconName
    action: MouseEventHandler<HTMLElement> | null
    color: string | undefined
  } => {
    switch (status) {
      case "available":
      case "completed":
        return { icon: "play-circle", text: "Disponible", action: null, color: "green" }
      case "importPending":
      case "warning":
      case "failed":
      case "downloadClientUnavailable":
      case "importBlocked":
      case "failedPending":
      case "ignored":
        return { icon: "warning", text: "Il y a un problème", action: null, color: "#b97a23" }
      case "unknown":
      case "notImported": {
        if (alreadyRequested) {
          return { icon: "clock", text: "Recherche...", action: null, color: "#546abc" }
        }
        return { icon: "download", text: "Importer", action: onImport, color: undefined }
      }
      case "watching": {
        if (alreadyRequested) {
          return { icon: "clock", text: "Recherche...", action: null, color: "#546abc" }
        }
        return { icon: "eye", text: "Surveillé", action: onRestartImport, color: "#546abc" }
      }
      case "delay":
      case "fallback":
      case "paused":
      case "queued":
      case "importing":
      case "imported":
      case "downloading":
      default:
        return {
          icon: "hourglass",
          text: "En cours d'import",
          action: null,
          color: "#546abc",
        }
    }
  }, [status])

  return (
    <div className={classes.container} style={style}>
      {downloadStatus?.download ? (
        <DownloadProgress download={downloadStatus.download} style={style} format={format} />
      ) : (
        <Button
          loading={isImporting}
          className={classes.importButton}
          icon={action.icon}
          iconProps={{ spin: action.icon === "clock" }}
          appearance="primary"
          style={{
            backgroundColor: action.color,
            cursor: action.action ? "pointer" : "default",
            pointerEvents: action.action ? "auto" : "none",
          }}
          onClick={action.action || undefined}
          disabled={isImporting}
          tooltip={format === "full" ? undefined : action.text}
        >
          {format === "full" ? action.text : null}
        </Button>
      )}{" "}
    </div>
  )
}
