import Bowser from "bowser"
import qs from "qs"

import { PLEX_CLIENT_IDENTIFIER } from "../../constants"

const activeInfo = Bowser.parse(window.navigator.userAgent)

function createAuthPopup(url: string, title: string, w: number, h: number) {
  // Fixes dual-screen position                         Most browsers      Firefox
  const dualScreenLeft = window.screenLeft != undefined ? window.screenLeft : window.screenX
  const dualScreenTop = window.screenTop != undefined ? window.screenTop : window.screenY
  const width = window.innerWidth
    ? window.innerWidth
    : document.documentElement.clientWidth
      ? document.documentElement.clientWidth
      : screen.width
  const height = window.innerHeight
    ? window.innerHeight
    : document.documentElement.clientHeight
      ? document.documentElement.clientHeight
      : screen.height
  const left = width / 2 - w / 2 + dualScreenLeft
  const top = height / 2 - h / 2 + dualScreenTop
  const authWindow = window.open(
    url,
    title,
    "scrollbars=yes, width=" + w + ", height=" + h + ", top=" + top + ", left=" + left,
  )

  authWindow?.focus()

  return authWindow!
}

function getPlexHeaders() {
  return {
    Accept: "application/json",
    "X-Plex-Product": "Ponpon.tv",
    "X-Plex-Version": "2.0",
    "X-Plex-Client-Identifier": PLEX_CLIENT_IDENTIFIER,
    "X-Plex-Model": "Plex OAuth",
    ...(activeInfo.os.name ? { "X-Plex-Platform": activeInfo.os.name } : {}),
    ...(activeInfo.os.version ? { "X-Plex-Platform-Version": activeInfo.os.version } : {}),
    ...(activeInfo.browser.version ? { "X-Plex-Device-Name": activeInfo.browser.version } : {}),
    ...(activeInfo.browser.name ? { "X-Plex-Device": activeInfo.browser.name } : {}),
    "X-Plex-Device-Screen-Resolution": window.screen.width + "x" + window.screen.height,
    "X-Plex-Language": "fr",
  }
}

async function getPlexAuthPin() {
  const result = await fetch("https://plex.tv/api/v2/pins?strong=true", {
    method: "POST",
    headers: new Headers(getPlexHeaders()),
  })

  const data = await result.json()

  return { pin: data.id, code: data.code }
}

export async function plexAuth(): Promise<string> {
  const authWindow = createAuthPopup("", "Connection à Ponpon.tv", 600, 700)
  const { pin, code } = await getPlexAuthPin()

  const headers = getPlexHeaders()
  const params = {
    clientID: headers["X-Plex-Client-Identifier"],
    "context[device][product]": headers["X-Plex-Product"],
    "context[device][version]": headers["X-Plex-Version"],
    "context[device][platform]": headers["X-Plex-Platform"],
    "context[device][platformVersion]": headers["X-Plex-Platform-Version"],
    "context[device][device]": headers["X-Plex-Device"],
    "context[device][deviceName]": headers["X-Plex-Device-Name"],
    "context[device][model]": headers["X-Plex-Model"],
    "context[device][screenResolution]": headers["X-Plex-Device-Screen-Resolution"],
    "context[device][layout]": "desktop",
    code: code,
  }

  authWindow.location = "https://app.plex.tv/auth/#!?" + qs.stringify(params)

  let timeout: any

  return new Promise((resolve, reject) => {
    ;(function poll() {
      fetch(`https://plex.tv/api/v2/pins/${pin}`, {
        headers: new Headers(getPlexHeaders()),
        signal: AbortSignal.timeout(10000),
      })
        .then((res) => {
          clearTimeout(timeout)
          return res.json()
        })
        .then(async (data) => {
          if (data.authToken) {
            authWindow.close()
            resolve(data.authToken)
          } else {
            clearTimeout(timeout)
            timeout = setTimeout(poll, 2000)
          }
        })
        .catch((err) => {
          authWindow.close()
          clearTimeout(timeout)
          reject(err)
        })
    })()
  })
}
