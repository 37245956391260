// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MultiRating_container__5bAYo {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 50px;
}

.MultiRating_rating__yWdHz {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 20px;
  font-weight: 400;
}
.MultiRating_rating__yWdHz:not(:first-child) {
  margin-left: 10px;
}

.MultiRating_logo__olYib img[src\$=".svg"] {
  height: 22px;
  max-width: 60px;
}
.MultiRating_logo__olYib img[src\$=".png"] {
  max-height: 30px;
  max-width: 50px;
}
.MultiRating_logo__olYib > svg {
  position: relative;
  top: 9px;
  height: 28px;
  max-width: 60px;
}`, "",{"version":3,"sources":["webpack://./src/components/Rating/MultiRating/MultiRating.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,YAAA;AACF;;AAEA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,eAAA;EACA,gBAAA;AACF;AAAE;EACE,iBAAA;AAEJ;;AAII;EACE,YAAA;EACA,eAAA;AADN;AAII;EACE,gBAAA;EACA,eAAA;AAFN;AAKE;EACE,kBAAA;EACA,QAAA;EACA,YAAA;EACA,eAAA;AAHJ","sourcesContent":[".container {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  height: 50px;\n}\n\n.rating {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  font-size: 20px;\n  font-weight: 400;\n  &:not(:first-child) {\n    margin-left: 10px;\n  }\n}\n\n.logo {\n  img {\n    &[src$=\".svg\"] {\n      height: 22px;\n      max-width: 60px;\n    }\n\n    &[src$=\".png\"] {\n      max-height: 30px;\n      max-width: 50px;\n    }\n  }\n  & > svg {\n    position: relative;\n    top: 9px;\n    height: 28px;\n    max-width: 60px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `MultiRating_container__5bAYo`,
	"rating": `MultiRating_rating__yWdHz`,
	"logo": `MultiRating_logo__olYib`
};
export default ___CSS_LOADER_EXPORT___;
