import moment from "moment"
import { Rate } from "rsuite"

import { FontIcon } from "components/FontIcon/FontIcon"
import { TMDBImage } from "components/TMDBImage/TMDBImage"
import { Content } from "types/Content"

import classes from "../Content.module.scss"
import { ImportButton } from "../ImportButton/ImportButton"

export function Serie(props: { serie: Content<"serie">; format?: "compact" | "full" }) {
  const { serie, format } = props

  const { name, poster_path, first_air_date, overview, vote_average } = serie.tmdbMedia || {}

  return (
    <div className={classes.container} media-format={format}>
      <div className={classes.image} media-format={format}>
        <TMDBImage src={poster_path} type="poster" size="w185" />
      </div>
      <div className={classes.infos} media-format={format}>
        <div className={classes.title} media-format={format}>
          <ImportButton
            style={{
              position: "absolute",
              top: format === "compact" ? 10 : 30,
              right: format === "compact" ? 10 : 20,
            }}
            media={serie}
          />
          <FontIcon icon="film" className="marginRight10" />
          {name}
          {first_air_date ? (
            <div className={classes.year}>{moment(first_air_date).format("YYYY")}</div>
          ) : null}
          <Rate
            className={classes.rate}
            allowHalf
            defaultValue={Math.round((vote_average / 2) * 2) / 2}
            readOnly
            size="xs"
          />
        </div>
        <div className={classes.summary} media-format={format}>
          {overview}
        </div>
      </div>
    </div>
  )
}
