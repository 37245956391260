// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Cell_cell__PJbGT,
.Cell_cellString__Sf7g1,
.Cell_cellStatus__YznJC,
.Cell_cellDate__i5So6 {
  display: flex;
  flex-flow: row nowrap;
  padding: 0 20px !important;
  height: 100%;
  width: 100%;
}

.Cell_cellDate__i5So6 {
  flex-flow: column;
  justify-content: center;
}
.Cell_cellDate__i5So6 .Cell_date__0X4TV {
  font-size: 0.8rem;
  font-weight: 600;
}
.Cell_cellDate__i5So6 .Cell_from__P6ttK {
  font-size: 0.6rem;
  font-weight: 400;
}

.Cell_cellString__Sf7g1 {
  align-items: center;
  font-weight: 600;
}

.Cell_cellDuration__WQE-5 {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  font-weight: 600;
  padding: 0 20px;
  width: 100%;
  height: 100%;
}

.Cell_cellNote__UAhgv {
  line-height: 50px;
}
.Cell_cellNote__UAhgv :first-child {
  font-size: 20px;
  font-family: "Gilroy";
}
.Cell_cellNote__UAhgv :last-child {
  font-size: 20px;
  font-family: "Gilroy";
}

.Cell_cellStatus__YznJC {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.Cell_cellStatus__YznJC .Cell_icon__hqgOp {
  width: 20px;
  height: 20px;
  cursor: pointer;
  margin-left: 20px;
}

.Cell_cellComment__qYnFJ {
  white-space: pre-wrap;
}

.Cell_cellRules__KWJU8 {
  width: 100%;
  max-width: 100%;
  max-height: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  white-space: pre-wrap;
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/components/Table/Cell.module.scss"],"names":[],"mappings":"AAAA;;;;EAIE,aAAA;EACA,qBAAA;EACA,0BAAA;EACA,YAAA;EACA,WAAA;AACF;;AAEA;EACE,iBAAA;EACA,uBAAA;AACF;AACE;EACE,iBAAA;EACA,gBAAA;AACJ;AAEE;EACE,iBAAA;EACA,gBAAA;AAAJ;;AAIA;EACE,mBAAA;EACA,gBAAA;AADF;;AAIA;EACE,aAAA;EACA,qBAAA;EACA,mBAAA;EACA,gBAAA;EACA,eAAA;EACA,WAAA;EACA,YAAA;AADF;;AAIA;EACE,iBAAA;AADF;AAGE;EACE,eAAA;EACA,qBAAA;AADJ;AAIE;EACE,eAAA;EACA,qBAAA;AAFJ;;AAMA;EACE,aAAA;EACA,mBAAA;EACA,mBAAA;AAHF;AAKE;EACE,WAAA;EACA,YAAA;EACA,eAAA;EACA,iBAAA;AAHJ;;AAOA;EACE,qBAAA;AAJF;;AAOA;EACE,WAAA;EACA,eAAA;EACA,gBAAA;EACA,aAAA;EACA,qBAAA;EACA,mBAAA;EACA,uBAAA;EACA,qBAAA;EACA,kBAAA;AAJF","sourcesContent":[".cell,\n.cellString,\n.cellStatus,\n.cellDate {\n  display: flex;\n  flex-flow: row nowrap;\n  padding: 0 20px !important;\n  height: 100%;\n  width: 100%;\n}\n\n.cellDate {\n  flex-flow: column;\n  justify-content: center;\n\n  .date {\n    font-size: 0.8rem;\n    font-weight: 600;\n  }\n\n  .from {\n    font-size: 0.6rem;\n    font-weight: 400;\n  }\n}\n\n.cellString {\n  align-items: center;\n  font-weight: 600;\n}\n\n.cellDuration {\n  display: flex;\n  flex-flow: row nowrap;\n  align-items: center;\n  font-weight: 600;\n  padding: 0 20px;\n  width: 100%;\n  height: 100%;\n}\n\n.cellNote {\n  line-height: 50px;\n\n  :first-child {\n    font-size: 20px;\n    font-family: \"Gilroy\";\n  }\n\n  :last-child {\n    font-size: 20px;\n    font-family: \"Gilroy\";\n  }\n}\n\n.cellStatus {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n\n  .icon {\n    width: 20px;\n    height: 20px;\n    cursor: pointer;\n    margin-left: 20px;\n  }\n}\n\n.cellComment {\n  white-space: pre-wrap;\n}\n\n.cellRules {\n  width: 100%;\n  max-width: 100%;\n  max-height: 100%;\n  display: flex;\n  flex-flow: row nowrap;\n  align-items: center;\n  justify-content: center;\n  white-space: pre-wrap;\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cell": `Cell_cell__PJbGT`,
	"cellString": `Cell_cellString__Sf7g1`,
	"cellStatus": `Cell_cellStatus__YznJC`,
	"cellDate": `Cell_cellDate__i5So6`,
	"date": `Cell_date__0X4TV`,
	"from": `Cell_from__P6ttK`,
	"cellDuration": `Cell_cellDuration__WQE-5`,
	"cellNote": `Cell_cellNote__UAhgv`,
	"icon": `Cell_icon__hqgOp`,
	"cellComment": `Cell_cellComment__qYnFJ`,
	"cellRules": `Cell_cellRules__KWJU8`
};
export default ___CSS_LOADER_EXPORT___;
