import { Media as MediaT } from "types/Content"

import { MovieOverview } from "./MovieOverview"
import { SerieOverview } from "./SerieOverview"

type MediaProps = {
  media: MediaT<any>
}

export function Media(props: MediaProps) {
  const { media } = props

  if (media.type === "movie") {
    return <MovieOverview movie={media} />
  }

  return <SerieOverview serie={media} />
}
