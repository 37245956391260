import { useState } from "react"
import { ModalProps as RSModalProps, Modal as RSModal, Button, ButtonProps, Loader } from "rsuite"

export type ModalActionProps = RSModalProps & {
  children: React.ReactNode
  title?: React.ReactNode
  onValidate?: () => Promise<any>
  onCancel?: () => Promise<any>
  onClose: (() => void) | undefined
  hideCancel?: boolean
  hideValidate?: boolean
  disabledCancel?: boolean
  disabledValidate?: boolean
  cancelLabel?: React.ReactNode
  validateLabel?: React.ReactNode
  cancelColor?: ButtonProps["color"]
  validateColor?: ButtonProps["color"]
  buttons?: React.ReactNode
}

export function ModalAction(props: ModalActionProps) {
  const {
    children,
    title,
    onValidate,
    onCancel,
    hideCancel,
    hideValidate,
    disabledCancel,
    disabledValidate,
    cancelLabel,
    validateLabel,
    cancelColor,
    validateColor,
    buttons,
    ...modalProps
  } = props

  const [isLoading, setIsLoading] = useState(false)

  function onLoading(func?: () => Promise<any>) {
    return function resolveFunction() {
      if (func) {
        setIsLoading(true)
        func().finally(() => setIsLoading(false))
      }
    }
  }

  return (
    <RSModal backdrop="static" autoFocus enforceFocus {...modalProps}>
      {title ? (
        <RSModal.Header>
          <RSModal.Title>
            <div className="bold">{title}</div>
          </RSModal.Title>
        </RSModal.Header>
      ) : null}
      <RSModal.Body>{children}</RSModal.Body>
      <RSModal.Footer>
        {buttons}
        {!hideValidate ? (
          <Button
            onClick={onLoading(onValidate)}
            appearance="primary"
            disabled={isLoading || disabledValidate}
            color={validateColor}
          >
            <div className="flex row center alignCenter">
              {isLoading ? <Loader speed="slow" className="marginRight5" /> : null}
              {validateLabel || "Ok"}
            </div>
          </Button>
        ) : null}
        {!hideCancel ? (
          <Button
            onClick={onLoading(onCancel)}
            appearance="subtle"
            disabled={isLoading || disabledCancel}
            color={cancelColor}
          >
            {cancelLabel || "Cancel"}
          </Button>
        ) : null}
      </RSModal.Footer>
    </RSModal>
  )
}
