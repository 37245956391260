import { useSelector } from "react-redux"

import { ImportButton } from "components/Content/ImportButton/ImportButton"
import { Field } from "components/Field/Field"
import { Modal } from "components/Modal/Modal"
import { MultiRating } from "components/Rating/MultiRating/MultiRating"
import { TMDBImage } from "components/TMDBImage/TMDBImage"
import { RootState } from "services/store"
import { Media } from "types/Content"

import classes from "./MediaDetail.module.scss"

type SerieDetailProps = {
  tmdbId: Media<"serie">["tmdbId"]
  onClose: () => void
  isOpen: boolean
}

export function SerieDetail(props: SerieDetailProps) {
  const { tmdbId, isOpen, onClose } = props

  const serie = useSelector(
    (state: RootState) => state.medias.medias[tmdbId] as Media<"serie"> | undefined,
  )
  if (!serie) return null

  const {
    year,
    images,
    // releaseDate,
    ratings,
    // studio,
    // website,
    // youTubeTrailerId,
    overview: mediaOverview,
  } = serie?.media || {}

  const overview = serie?.tmdbMedia.overview || mediaOverview
  const title = serie?.tmdbMedia.name

  const poster = serie?.tmdbMedia.poster_path
  const fanart = images?.find((image) => image.coverType === "fanart")?.remoteUrl

  return (
    <Modal
      title={
        <div className="flex row noWrap spaceBetween relative" style={{ zIndex: 1 }}>
          <div className={classes.title}>
            <div className="flex row noWrap alignCenter">
              <h3>{title}</h3>
              <div className="marginLeft10 lightgrey fontSize22">{year}</div>
            </div>
            <div className="lightgrey fontSize16">{serie?.tmdbMedia.tagline}</div>{" "}
          </div>
          <ImportButton media={serie} />
        </div>
      }
      backdrop="static"
      isOpen={isOpen && !!serie}
      onClose={onClose}
      size={1000}
    >
      <div className={classes.background} style={{ backgroundImage: `url('${fanart}')` }} />
      <div className={classes.container}>
        <div className={classes.mediaInfos}>
          <div className={classes.imageContainer}>
            <TMDBImage type="poster" src={poster} className={classes.image} />
            <div className="flex center">
              <MultiRating
                ratings={{ tmdb: { value: ratings.value, votes: ratings.votes, type: "" } }}
              />
            </div>
          </div>
          <div className={classes.infos}>
            {/* <Field
              name="Date de sortie"
              icon={moment(releaseDate).isBefore() ? "calendar" : "bullhorn"}
              value={moment(releaseDate).format("LL")}
              className={classes.field}
            /> */}

            <Field
              name="Genre"
              icon="tag"
              value={serie.tmdbMedia.genres?.map((genre) => genre.name).join(", ")}
              className={classes.field}
            />
            {/* <Field
              name="Pays de production"
              icon="earth-america"
              value={serie.tmdbMedia.production_countries?.map((genre) => genre.name).join(", ")}
              className={classes.field}
            /> */}
            {/* <Field name="Studio" icon="industry" value={studio} className={classes.field} /> */}
            <Field
              name="Synopsis"
              icon="circle-info"
              value={overview}
              className={classes.field}
              style={{ margin: "50px 0px" }}
            />
            {/* <Field
              name="Trailer"
              icon="circle-play"
              format="youtube"
              value={youTubeTrailerId}
              className={classes.field}
              style={{ margin: "50px 0px" }}
            />
            {website ? (
              <Field
                name="Site web"
                icon="globe"
                value={
                  <a href={website} target="_blank" rel="noreferrer">
                    {website}
                  </a>
                }
                className={classes.field}
              />
            ) : null} */}
          </div>
        </div>
      </div>
    </Modal>
  )
}
