import { forwardRef, ReactNode, useContext } from "react"
import { Badge, Popover, PopoverProps, Whisper } from "rsuite"

import { IconButton } from "components/Button/IconButton"
import { CellContent } from "components/Content/CellContent"
import { AppContext } from "contexts/AppContexts"

import classes from "./Downloads.module.scss"

const Poppover = forwardRef(({ content, ...props }: { content: ReactNode } & PopoverProps, ref) => {
  return (
    <Popover ref={ref} {...props}>
      {content}
    </Popover>
  )
})

export function Downloads() {
  const { downloads = {} } = useContext(AppContext)

  const downloadsContent = Object.values(downloads).map((d) => (
    <div
      key={d.tmdbId}
      style={{ maxWidth: 500, width: 500, overflow: "hidden", position: "relative" }}
    >
      {CellContent(d, "compact")}
    </div>
  ))

  if (!downloadsContent.length) return null

  return (
    <div className={classes.container}>
      <Whisper
        trigger="click"
        placement="autoVertical"
        controlId="downloads-control-id"
        speaker={<Poppover content={downloadsContent} />}
      >
        <Badge content={downloadsContent.length}>
          <IconButton icon="download" appearance="subtle" />
        </Badge>
      </Whisper>
    </div>
  )
}
