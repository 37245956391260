// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Field_container__tVVOn {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.Field_icon__nm6Nm {
  margin-right: 10px;
  color: lightgrey;
}

.Field_key__oA\\+MG {
  display: inline-flex;
  color: grey;
  font-size: 0.8rem;
}

.Field_value__RRMAe {
  display: inline-flex;
}`, "",{"version":3,"sources":["webpack://./src/components/Field/Field.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,WAAA;AACF;;AAEA;EACE,kBAAA;EACA,gBAAA;AACF;;AAEA;EACE,oBAAA;EACA,WAAA;EACA,iBAAA;AACF;;AAEA;EACE,oBAAA;AACF","sourcesContent":[".container {\n  display: flex;\n  flex-direction: row;\n  width: 100%;\n}\n\n.icon {\n  margin-right: 10px;\n  color: lightgrey;\n}\n\n.key {\n  display: inline-flex;\n  color: grey;\n  font-size: 0.8rem;\n}\n\n.value {\n  display: inline-flex;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Field_container__tVVOn`,
	"icon": `Field_icon__nm6Nm`,
	"key": `Field_key__oA+MG`,
	"value": `Field_value__RRMAe`
};
export default ___CSS_LOADER_EXPORT___;
