import { BaseQueryFn, EndpointBuilder } from "@reduxjs/toolkit/dist/query"
import queryString from "query-string"

import {
  CreateParams,
  DeleteParams,
  ID,
  ItemWithId,
  ReadAllParams,
  ReadParams,
  UpdateParams,
} from "types/API"

import { transformRequest, transformResponse, filterResponse } from "./utils"

export const createCRUDEndpoints = <
  Build extends EndpointBuilder<BaseQueryFn, string, string>,
  Item extends { id: ID },
>(
  build: Build,
  endpoint: string,
  tagType: string,
) => ({
  create: build.mutation<Item, CreateParams<Item>>({
    query: ({ queries, data }) => ({
      url: `/${endpoint}/`,
      method: "POST",
      body: transformRequest(data),
      params: queries,
    }),
    transformResponse: (response: { data: Item }) => transformResponse<Item>(response.data),
    invalidatesTags: [{ type: tagType, id: "LIST" }],
  }),

  get: build.query<Item, ReadParams>({
    query: ({ id, queries }) => ({
      url: `/${endpoint}/${id}${queries ? `?${queryString.stringify(queries)}` : ""}`,
      method: "GET",
      params: queries,
    }),
    transformResponse: (response: { data: Item }) => transformResponse<Item>(response.data),
    providesTags: (result, error, { id }) => [{ type: tagType, id }],
  }),

  getAll: build.query<Item[], ReadAllParams | void>({
    query: ({ queries } = {}) => ({
      url: `/${endpoint}/`,
      method: "GET",
      params: queries,
    }),
    transformResponse: (response: { data: Item[] }, meta, params) =>
      transformResponse<Item[]>(filterResponse<Item>(response.data, params?.filters)),
    providesTags: (result) => {
      if (!result) return [{ type: tagType, id: "LIST" }]
      return [
        ...result.map(({ id }) => ({ type: tagType, id }) as const),
        { type: tagType, id: "LIST" },
      ]
    },
  }),

  update: build.mutation<Item, UpdateParams<ItemWithId<Item>>>({
    query: ({ queries, data }) => {
      if (Array.isArray(data)) {
        return {
          url: `/${endpoint}/`,
          method: "PATCH",
          body: transformRequest(data),
          params: queries,
          headers: new Headers({ "bulk-update": "true" }),
        }
      }
      return {
        url: `/${endpoint}/${data.id}/`,
        method: "PATCH",
        body: transformRequest(data),
        params: queries,
      }
    },
    transformResponse: (response: { data: Item }) => transformResponse<Item>(response.data),
    invalidatesTags: (result) => {
      if (!result) return []
      return [
        { type: tagType, id: result.id },
        { type: tagType, id: "LIST" },
      ]
    },
  }),

  delete: build.mutation<void, DeleteParams>({
    query: (id) => ({
      url: `/${endpoint}/${id}/`,
      method: "DELETE",
    }),
    invalidatesTags: (result, error, id) => {
      if (Array.isArray(id)) return id.map((id) => ({ type: tagType, id }))
      return [{ type: tagType, id }]
    },
  }),
})
