import { createApi } from "@reduxjs/toolkit/query/react"

import { Invitation } from "types/Invitation"

import { baseQuery } from "./baseQuery"
import { createCRUDEndpoints } from "./createCRUDEndpoints"

const invitationsApi = createApi({
  reducerPath: "invitations",
  baseQuery: baseQuery,
  tagTypes: ["Invitation"],
  endpoints: (build) => ({
    ...(() => {
      const endpoints = createCRUDEndpoints<typeof build, Invitation>(
        build,
        "invitations",
        "Invitation",
      )
      return {
        createInvitation: endpoints.create,
        getInvitation: endpoints.get,
        getAllInvitation: endpoints.getAll,
        updateInvitation: endpoints.update,
        deleteInvitation: endpoints.delete,
      }
    })(),
  }),
})

export { invitationsApi }
