import { Movie } from "components/Content/Movie/Movie"
import { Serie } from "components/Content/Serie/Serie"
import { Content } from "types/Content"

export function CellContent<T extends "movie" | "serie">(
  data: Content<T>,
  format?: "compact" | "full",
) {
  if (data.type === "movie") {
    return <Movie format={format} movie={data as Content<"movie">} />
  }
  if (data.type === "serie") {
    return <Serie format={format} serie={data as Content<"serie">} />
  }

  return null
}
