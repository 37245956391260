import { useEffect, useRef, useState } from "react"
import { Button, Nav } from "rsuite"

import request from "api/request"
import { IconButton } from "components/Button/IconButton"
import { Modal } from "components/Modal/Modal"
import { SearchInput } from "components/SearchInput/SearchInput"
import { useAuthenticate } from "hooks/useAuthenticate"
import { useErrorsHandling } from "hooks/useErrorsHandling"
import { RoleType } from "roles"
import { Downloads } from "views/Downloads/Downloads"
import { Invitations } from "views/Invitations/Invitations"
import { Requests } from "views/Requests/Requests"
import { Search } from "views/Search/Search"
import { Users } from "views/Users/Users"

import { PLEX_URI } from "./constants"

import classes from "./PonponMenu.module.scss"

import "styles/index.scss"

export function PonponMenu() {
  const [menuOpen, setMenuOpen] = useState(false)
  const [page, setPage] = useState<"search" | "invite" | "users" | "requests">("search")
  const [iframeIsLoaded, setIframeIsLoaded] = useState(false)
  const [initialSearch, setInitialSearch] = useState("")

  const iframeRef = useRef<HTMLIFrameElement>(null)

  const { isLoggedIn, isLogging, authenticate, me } = useAuthenticate()

  useErrorsHandling({ isLoggedIn })

  const { role = 999 } = me || {}

  function iframeLoaded() {
    setIframeIsLoaded(true)
  }

  function iframeUnloaded() {
    setIframeIsLoaded(false)
  }

  function onOpenPage(page: "search" | "invite" | "users" | "requests") {
    setPage(page)
    setMenuOpen(true)
  }

  useEffect(() => {
    if (isLoggedIn && iframeRef.current) {
      iframeRef.current.addEventListener("load", iframeLoaded)
      iframeRef.current.addEventListener("unload", iframeUnloaded)
    }
    return () => {
      if (iframeRef.current) {
        iframeRef.current.removeEventListener("load", iframeLoaded)
        iframeRef.current.removeEventListener("unload", iframeUnloaded)
      }
    }
  }, [isLoggedIn])

  return !isLoggedIn ? (
    <div className="relative fullWidth fullHeight flex center alignCenter">
      <div className="flex column center alignCenter">
        <div className="fontSize30">
          Ponpon<span style={{ color: "#e5a00c" }}>.tv</span>
        </div>

        <Button
          loading={isLogging}
          style={{ width: 200, marginTop: 20 }}
          onClick={() => authenticate()}
          appearance="primary"
        >
          Connection
        </Button>
      </div>
    </div>
  ) : (
    <div className="relative fullWidth fullHeight">
      <iframe
        ref={iframeRef}
        allow="clipboard-read; clipboard-write; camera; microphone; speaker-selection; encrypted-media; web-share; display-capture; autoplay; fullscreen; picture-in-picture"
        frameBorder="0"
        id="frame-3"
        sandbox="allow-presentation allow-forms allow-same-origin allow-orientation-lock allow-pointer-lock allow-scripts allow-popups allow-popups-to-escape-sandbox allow-modals allow-top-navigation allow-top-navigation-by-user-activation allow-downloads"
        scrolling="auto"
        src={PLEX_URI}
        style={{
          width: "100%",
          height: "100%",
        }}
      />

      {iframeIsLoaded ? (
        <div className={classes.container}>
          <div className={classes.menuBar}>
            <div className="flex row noWrap fullWidth">
              <Button
                style={{
                  backgroundColor: "rgb(10 10 10)",
                  fontSize: 20,
                }}
              >
                Ponpon<span style={{ color: "#e5a00c" }}>.tv</span>
              </Button>
              <div>
                <SearchInput
                  onOpenPage={onOpenPage}
                  onSearch={(value) => setInitialSearch(value)}
                  value={initialSearch}
                  setValue={setInitialSearch}
                />
              </div>
            </div>
            <IconButton
              tooltip="Se déconnecter"
              icon="right-from-bracket"
              onClick={() => request.auth.logout()}
              appearance="subtle"
            />
            <Downloads />
          </div>
        </div>
      ) : null}
      <Modal
        onOpen={() => setInitialSearch("")}
        title={
          <Nav activeKey={page} onSelect={(v) => setPage(v)}>
            <Nav.Item eventKey="search">Rechercher</Nav.Item>
            <Nav.Item eventKey="requests">Mes requètes</Nav.Item>
            {role <= RoleType.ADMIN && <Nav.Item eventKey="invite">Invitations</Nav.Item>}
            {role <= RoleType.ADMIN && <Nav.Item eventKey="users">Utilisateurs</Nav.Item>}
          </Nav>
        }
        isOpen={menuOpen}
        onClose={() => setMenuOpen(false)}
        size="full"
      >
        {page === "search" ? <Search initialSearch={initialSearch} /> : null}
        {page === "invite" ? <Invitations /> : null}
        {page === "users" ? <Users /> : null}
        {page === "requests" ? <Requests /> : null}
      </Modal>
    </div>
  )
}
