import { ReactNode } from "react"
import { Tooltip as RSTooltip, Whisper } from "rsuite"

import classes from "./Tooltip.module.scss"

export interface TooltipProps {
  placement?:
    | "top"
    | "bottom"
    | "right"
    | "left"
    | "bottomStart"
    | "bottomEnd"
    | "topStart"
    | "topEnd"
    | "leftStart"
    | "rightStart"
    | "leftEnd"
    | "rightEnd"
    | "auto"
    | "autoVertical"
    | "autoVerticalStart"
    | "autoVerticalEnd"
    | "autoHorizontal"
    | "autoHorizontalStart"
    | "autoHorizontalEnd"
  delay?: number
  bold?: boolean
  contentText?: string
  content?: React.JSX.Element | ReactNode | undefined | string | null | number
  children: React.JSX.Element
  tooltipViewStyle?: any
  disabled?: boolean
  noStyle?: boolean
}

export function Tooltip(props: TooltipProps): React.JSX.Element {
  const {
    contentText,
    children,
    placement = "autoVertical",
    delay = 100,
    bold = true,
    tooltipViewStyle,
    disabled,
    content,
    noStyle,
  } = props

  if (disabled) return children

  return (
    <Whisper
      controlId="control-id-hover"
      delay={delay}
      placement={placement}
      disabled={disabled}
      preventOverflow
      speaker={
        <RSTooltip
          className={noStyle ? undefined : classes.tooltip}
          style={tooltipViewStyle}
          arrow={false}
        >
          {content ||
            (contentText ? (
              <span
                className={classes.tooltipText}
                style={{ fontWeight: bold ? "bold" : "normal" }}
              >
                {contentText}
              </span>
            ) : null)}
        </RSTooltip>
      }
    >
      {children}
    </Whisper>
  )
}
