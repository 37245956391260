import { IconName } from "@fortawesome/fontawesome-svg-core"
import { Fragment } from "react/jsx-runtime"
import { IconButtonProps as RSIconButtonProps, IconButton as RSIconButon } from "rsuite"

import { FontIcon } from "components/FontIcon/FontIcon"
import { Tooltip } from "components/Tooltip/Tooltip"

// import classes from "./IconButton.module.scss"

type IconButtonProps = Omit<RSIconButtonProps, "icon"> & {
  icon: IconName
  tooltip?: string
}

export function IconButton(props: IconButtonProps) {
  const { icon, tooltip, ...buttonProps } = props

  const Root = tooltip
    ? (props: any) => <Tooltip content={tooltip} {...props} />
    : (props: any) => <Fragment {...props} />

  return (
    <Root>
      <RSIconButon icon={<FontIcon icon={icon} />} {...buttonProps} />
    </Root>
  )
}
