import { ReactNode } from "react"
import { Modal as RSModal, ModalProps as RSModalProps } from "rsuite"

export type ModalProps = RSModalProps & {
  isOpen: boolean
  onClose: () => void
  title?: ReactNode | string
  headerContent?: React.ReactNode
}

export function Modal({
  title,
  headerContent,
  isOpen,
  onClose,
  children,
  ...modalProps
}: ModalProps) {
  return (
    <RSModal open={isOpen} onClose={onClose} {...modalProps}>
      {title ? (
        <RSModal.Header closeButton>
          <div
            className="fullWidth flex row noWrap alignCenter spaceBetween"
            style={{ width: "calc(100% - 30px)" }}
          >
            <RSModal.Title>{title}</RSModal.Title>
            <div>{headerContent}</div>
          </div>
        </RSModal.Header>
      ) : null}
      <RSModal.Body>{children}</RSModal.Body>
    </RSModal>
  )
}
