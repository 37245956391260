import { get } from "lodash"
import moment from "moment"
import { useState } from "react"
import { useSelector } from "react-redux"
import { CellProps as RsCellProps, SelectPicker, Table } from "rsuite"
import { RowDataType } from "rsuite-table"

import api from "api"
import { notify } from "components/Notification/Notification"
import { RoleType } from "roles"
import { RootState } from "services/store"
import { User } from "types/User"

import classes from "./Cell.module.scss"

export type CellProps<Row extends RowDataType> = RsCellProps<Row> & {
  cell?: (value: any) => JSX.Element | null
  accessor?: (row: Row | undefined) => any
}

export function Cell<Row extends RowDataType>(props: CellProps<Row>) {
  const { rowData, dataKey, accessor, cell, ...cellProps } = props
  const value = dataKey ? get(rowData, dataKey) : undefined
  const accessorValue = accessor?.(rowData)

  return (
    <Table.Cell
      {...cellProps}
      style={{ ...cellProps.style, maxWidth: cellProps.style?.minWidth, padding: 0 }}
      rowData={rowData}
      dataKey={dataKey as string}
      fullText
    >
      {cell?.(accessorValue || value) || value}
    </Table.Cell>
  )
}

export function cellDate(value: string) {
  if (!value) return null
  const date = moment(value)
  return (
    <div className={classes.cellDate}>
      <div className={classes.date}>{date.format("L LT")}</div>
      <div className={classes.from}>{date.fromNow()}</div>
    </div>
  )
}

export function cellString(value: string) {
  return <div className={classes.cellString}>{value}</div>
}

export function cellNumber(value: number) {
  return <div className={classes.cellNumber}>{value}</div>
}

export function cellRole(user: User) {
  const me = useSelector((state: RootState) => state.me.me)
  const [updateUser] = api.users.update()

  const [loading, setLoading] = useState(false)

  return (
    <SelectPicker
      searchable={false}
      cleanable={false}
      caretAs="span"
      loading={loading}
      readOnly={!me || me.role !== 0 || user.role === RoleType.SUPERADMIN}
      disabled={loading}
      appearance="subtle"
      value={user.role}
      data={[
        { label: "Utilisateur", value: RoleType.USER },
        { label: "Super utilisateur", value: RoleType.SUPERUSER },
        { label: "Administrateur", value: RoleType.ADMIN },
        { label: "Super administrateur", value: RoleType.SUPERADMIN },
      ]}
      onChange={async (value) => {
        try {
          setLoading(true)
          await updateUser({ data: { id: user.id, role: value as number } })
        } catch (error: any) {
          notify.error(error.message)
        }
        setLoading(false)
      }}
    />
  )
}
