import { get } from "lodash"
import moment from "moment"
import { useMemo, useRef } from "react"
import { Pagination, Table as RsTable, TableProps as RsTableProps } from "rsuite"
import { RowDataType, RowKeyType } from "rsuite/Table"

import { MultiRes } from "types/API"

import { Cell } from "./Cell"

import "./Table.scss"

const { Column, HeaderCell } = RsTable

export type ColumnContent = {
  title: string
  dataKey: string
  align?: "center"
  sortable?: boolean
  cell?: (value: any) => JSX.Element | null
  accessor?: <Row extends RowDataType>(row: Row | undefined) => any
  grow?: number
  cellStyle?: React.CSSProperties
}

export type TableProps<RowItem> = RsTableProps<RowDataType<RowItem>, RowKeyType> & {
  data: RowItem[]
  columns: ColumnContent[]
  pagination?: MultiRes<any>
  onChangePage?: (page: number) => void
  onChangeLimit?: (limit: number) => void
}

export function Table<RowItem>(props: TableProps<RowItem>) {
  const {
    columns,
    className,
    sortColumn,
    sortType,
    data,
    pagination,
    onChangePage,
    onChangeLimit,
    rowHeight = 50,
    ...tableProps
  } = props

  const tableRef = useRef<any>()

  const { totalCount, page, limit, maxLimit } = pagination || {}

  const sortedData = useMemo(() => {
    return !sortColumn
      ? data
      : data.sort((a, b) => {
          const prev = sortType === "desc" ? a : b
          const next = sortType === "desc" ? b : a

          const prevValue = get(prev, sortColumn)
          const nextValue = get(next, sortColumn)

          if (typeof prevValue === "string" || typeof nextValue === "string") {
            if (typeof prevValue !== "string") return 1
            if (typeof nextValue !== "string") return -1

            if (
              moment(prevValue, moment.ISO_8601).isValid() &&
              moment(nextValue, moment.ISO_8601).isValid()
            ) {
              return new Date(nextValue).getTime() - new Date(prevValue).getTime()
            }

            return prevValue.localeCompare(nextValue)
          }

          if (typeof prevValue === "number" || typeof nextValue === "number") {
            if (typeof prevValue !== "number") return -1
            if (typeof nextValue !== "number") return 1
            return prevValue - nextValue
          }

          return 0
        })
  }, [data, sortColumn, sortType])

  const limits = useMemo(
    () =>
      maxLimit
        ? ([
            5 <= maxLimit ? 5 : maxLimit,
            10 <= maxLimit ? 10 : null,
            20 <= maxLimit ? 20 : null,
            50 <= maxLimit ? 50 : null,
            100 <= maxLimit ? 100 : null,
            200 <= maxLimit ? 200 : null,
          ].filter(Boolean) as number[])
        : undefined,
    [maxLimit],
  )

  return (
    <div style={{ width: "100%", height: `calc(100% - ${pagination ? 36 : 0}px)` }}>
      <RsTable
        className={`twTable ${className || ""}`}
        data={sortedData}
        sortColumn={sortColumn}
        sortType={sortType}
        rowHeight={rowHeight}
        ref={(ref) => {
          tableRef.current = ref
        }}
        {...tableProps}
      >
        {columns.map((column) => (
          <Column
            flexGrow={column.grow}
            key={column.title}
            align={column.align || undefined}
            sortable={column.sortable}
          >
            <HeaderCell>{column.title}</HeaderCell>
            <Cell
              dataKey={column.dataKey}
              accessor={column.accessor}
              cell={column.cell || undefined}
              style={column.cellStyle}
            />
          </Column>
        ))}
      </RsTable>
      {pagination ? (
        <Pagination
          layout={["total", "-", limits ? "limit" : "|", "|", "pager", "skip"]}
          prev
          next
          first
          last
          ellipsis
          boundaryLinks
          total={totalCount || 0}
          limit={limit}
          limitOptions={limits}
          maxButtons={9}
          activePage={page}
          onChangePage={
            onChangePage
              ? (page) => {
                  onChangePage(page)
                  tableRef.current?.scrollTop()
                }
              : undefined
          }
          onChangeLimit={onChangeLimit}
        />
      ) : null}
    </div>
  )
}
