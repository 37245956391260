import { invitationsApi } from "./invitations"
import { usersApi } from "./users"

const api = {
  invitations: {
    ...invitationsApi,
    create: invitationsApi.useCreateInvitationMutation,
    get: invitationsApi.useGetInvitationQuery,
    getAll: invitationsApi.useGetAllInvitationQuery,
    update: invitationsApi.useUpdateInvitationMutation,
    delete: invitationsApi.useDeleteInvitationMutation,
  },
  users: {
    ...usersApi,
    get: usersApi.useGetUserQuery,
    getAll: usersApi.useGetAllUserQuery,
    delete: usersApi.useDeleteUserMutation,
    update: usersApi.useUpdateUserMutation,
  },
}

export default api
