import { createContext, useMemo, useState } from "react"

import { MovieDetail } from "components/Media/MovieDetail"
import { SerieDetail } from "components/Media/SerieDetail"
import { useDownloads } from "hooks/useDownloads"
import { Media } from "types/Content"

export type AppContextType = {
  downloads: Record<string, Media<any>>
  openMediaDetail: (media: Media<any> | undefined) => void
}

export const AppContext = createContext<AppContextType>({
  downloads: {},
  openMediaDetail: () => undefined,
})

export function AppContextProvider(props: { children: React.ReactNode }) {
  const { children } = props

  const { downloads } = useDownloads()

  const [media, openMediaDetail] = useState<Media<any> | undefined>()

  const examContextValue = useMemo(
    (): AppContextType => ({ downloads, openMediaDetail }),
    [downloads],
  )

  return (
    <AppContext.Provider value={examContextValue}>
      {children}
      {media?.type === "movie" ? (
        <MovieDetail
          isOpen={media?.type === "movie"}
          tmdbId={media?.tmdbId || ""}
          onClose={() => openMediaDetail(undefined)}
        />
      ) : null}
      {media?.type === "serie" ? (
        <SerieDetail
          isOpen={media?.type === "serie"}
          tmdbId={media?.tmdbId || ""}
          onClose={() => openMediaDetail(undefined)}
        />
      ) : null}
    </AppContext.Provider>
  )
}
