// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SearchInput_container__bT8\\+T {
  border-radius: 20px !important;
  height: 35px !important;
  border: none !important;
}
.SearchInput_container__bT8\\+T:hover, .SearchInput_container__bT8\\+T:focus-visible, .SearchInput_container__bT8\\+T:focus, .SearchInput_container__bT8\\+T:focus-within {
  background-color: rgba(255, 255, 255, 0.1) !important;
}
.SearchInput_container__bT8\\+T .SearchInput_input__oXb\\+b {
  background-color: transparent !important;
  width: 150px !important;
  transition: width 0.33s;
}
.SearchInput_container__bT8\\+T .SearchInput_input__oXb\\+b:focus {
  width: 250px !important;
}
.SearchInput_container__bT8\\+T .SearchInput_menu__H1fFB {
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/SearchInput/SearchInput.module.scss"],"names":[],"mappings":"AAAA;EACE,8BAAA;EACA,uBAAA;EACA,uBAAA;AACF;AACE;EAIE,qDAAA;AAFJ;AAKE;EACE,wCAAA;EACA,uBAAA;EACA,uBAAA;AAHJ;AAII;EACE,uBAAA;AAFN;AAME;EACE,eAAA;AAJJ","sourcesContent":[".container {\n  border-radius: 20px !important;\n  height: 35px !important;\n  border: none !important;\n\n  &:hover,\n  &:focus-visible,\n  &:focus,\n  &:focus-within {\n    background-color: rgba(255, 255, 255, 0.1) !important;\n  }\n\n  .input {\n    background-color: transparent !important;\n    width: 150px !important;\n    transition: width 0.33s;\n    &:focus {\n      width: 250px !important;\n    }\n  }\n\n  .menu {\n    cursor: pointer;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `SearchInput_container__bT8+T`,
	"input": `SearchInput_input__oXb+b`,
	"menu": `SearchInput_menu__H1fFB`
};
export default ___CSS_LOADER_EXPORT___;
