// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Button_icon__Zd50D {
  font-weight: inherit;
  font-size: inherit;
}

.Button_imageIcon__vq4qL {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Button_imageIcon__vq4qL img {
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/Button/Button.module.scss"],"names":[],"mappings":"AAAA;EACE,oBAAA;EACA,kBAAA;AACF;;AAEA;EACE,YAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AACF;AACE;EACE,YAAA;AACJ","sourcesContent":[".icon {\n  font-weight: inherit;\n  font-size: inherit;\n}\n\n.imageIcon {\n  height: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  img {\n    height: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon": `Button_icon__Zd50D`,
	"imageIcon": `Button_imageIcon__vq4qL`
};
export default ___CSS_LOADER_EXPORT___;
