// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DownloadProgress_container__GgvpJ {
  width: 160px;
  height: 20px;
  border-radius: 10px;
  overflow: hidden;
  border: 1px solid rgba(255, 255, 255, 0.2);
  position: relative;
  text-align: center;
}
.DownloadProgress_container__GgvpJ[size-format=compact][has-error=true] {
  width: 30px;
}

.DownloadProgress_progressBar__6-HtG {
  border-radius: 10px;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.DownloadProgress_speed__eZ0VW {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 12px;
}`, "",{"version":3,"sources":["webpack://./src/components/DownloadProgress/DownloadProgress.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,YAAA;EACA,mBAAA;EACA,gBAAA;EACA,0CAAA;EACA,kBAAA;EACA,kBAAA;AACF;AACE;EACE,WAAA;AACJ;;AAGA;EACE,mBAAA;EACA,YAAA;EACA,WAAA;EACA,kBAAA;EACA,MAAA;EACA,OAAA;AAAF;;AAGA;EACE,kBAAA;EACA,MAAA;EACA,OAAA;EACA,WAAA;EACA,YAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,WAAA;EACA,eAAA;AAAF","sourcesContent":[".container {\n  width: 160px;\n  height: 20px;\n  border-radius: 10px;\n  overflow: hidden;\n  border: 1px solid rgba(255, 255, 255, 0.2);\n  position: relative;\n  text-align: center;\n\n  &[size-format=\"compact\"][has-error=\"true\"] {\n    width: 30px;\n  }\n}\n\n.progressBar {\n  border-radius: 10px;\n  height: 100%;\n  width: 100%;\n  position: absolute;\n  top: 0;\n  left: 0;\n}\n\n.speed {\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  color: #fff;\n  font-size: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `DownloadProgress_container__GgvpJ`,
	"progressBar": `DownloadProgress_progressBar__6-HtG`,
	"speed": `DownloadProgress_speed__eZ0VW`
};
export default ___CSS_LOADER_EXPORT___;
