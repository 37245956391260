/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useMemo, useState } from "react"
import { useSelector } from "react-redux"
import { Input, Loader, Nav } from "rsuite"

import request from "api/request"
import { Button } from "components/Button/Button"
import { MediaList } from "components/MediaList/MediaList"
import { Table } from "components/Table"
import { useThrottledState } from "hooks/useThrottledState"
import { RootState } from "services/store"
import { MultiRes } from "types/API"
import { Media, MediaType } from "types/Content"

import { CellContent } from "../../components/Content/CellContent"

import classes from "./Search.module.scss"

type SearchProps = {
  initialSearch?: string
  onOpened?: () => void
}

export function Search<T extends MediaType>(props: SearchProps) {
  const { initialSearch } = props

  const [page, onChangePage] = useState(1)
  const [limit, onChangeLimit] = useState(20)
  const [response, setResponse] = useState<MultiRes<Media<T>>>()
  const [reloadRandom, setReloadRandom] = useState(0)
  const [type, setType] = useState<MediaType>("movie")
  const [isFetching, setIsFetching] = useState(false)
  const [throttledSearchValue, searchValue, setSearchValue] = useThrottledState<string>(
    initialSearch || "",
    500,
  )

  const queries = useMemo<any>(
    () => ({
      search: throttledSearchValue || undefined,
      type,
      page,
      limit,
    }),
    [limit, page, throttledSearchValue, type],
  )

  const fetch = useCallback(async () => {
    if (!throttledSearchValue) return
    setIsFetching(true)
    const response = await request.medias.search<T>(queries)
    setResponse(response)
    setIsFetching(false)
  }, [queries, throttledSearchValue])

  useEffect(() => void fetch(), [queries, fetch, !searchValue ? reloadRandom : ""])

  useEffect(() => setResponse(undefined), [type])

  useEffect(() => onChangePage(1), [searchValue])

  const medias = useSelector((state: RootState) => state.medias.medias)

  return (
    <>
      <div className="fullWidth flex row spaceBetween alignCenter" style={{ height: 50 }}>
        <Nav activeKey={type} onSelect={(v) => setType(v)}>
          <Nav.Item eventKey="movie">Films</Nav.Item>
          <Nav.Item eventKey="serie">Séries</Nav.Item>
        </Nav>
        <div className="flex row alignCenter noWrap">
          {isFetching ? (
            <Loader className="marginRight10" />
          ) : !searchValue ? (
            <Button icon="refresh" onClick={() => setReloadRandom((v) => v + 1)} />
          ) : null}
          <Input
            autoComplete="off"
            onChange={setSearchValue}
            value={searchValue}
            placeholder="Rechercher un film ou une serie"
            style={{ width: 300 }}
          />
        </div>
      </div>
      <div
        className="fullWidth fullHeight"
        style={{
          overflowY: "auto",
          width: "calc(100% - 20px)",
          padding: "0 10px",
          height: "calc(100% - 50px)",
        }}
      >
        {!searchValue ? (
          <div className={classes.discover}>
            <MediaList
              title="Populaire"
              type={type}
              request={(queries) =>
                request.medias.discover({ ...queries, discoverType: "popular" })
              }
            />
            <MediaList
              title="Le meilleur de la semaine"
              type={type}
              request={(queries) =>
                request.medias.discover({ ...queries, discoverType: "top_rated" })
              }
            />
            {type === "movie" ? (
              <>
                <MediaList
                  title="En ce moment au cinéma"
                  type={type}
                  request={(queries) =>
                    request.medias.discover({ ...queries, discoverType: "now_playing" })
                  }
                />
                <MediaList
                  title="Prochainement"
                  type={type}
                  request={(queries) =>
                    request.medias.discover({ ...queries, discoverType: "upcoming" })
                  }
                />
              </>
            ) : (
              <>
                <MediaList
                  title="En ce moment à la TV"
                  type={type}
                  request={(queries) =>
                    request.medias.discover({ ...queries, discoverType: "on_the_air" })
                  }
                />
                <MediaList
                  title="Aujourd'hui à la TV"
                  type={type}
                  request={(queries) =>
                    request.medias.discover({ ...queries, discoverType: "airing_today" })
                  }
                />
              </>
            )}
          </div>
        ) : (
          <Table
            data={response?.results.map((item) => medias[item.tmdbId]) || []}
            style={{ display: searchValue ? "block" : "none" }}
            rowHeight={150}
            columns={[
              {
                grow: 1,
                title: "Titre",
                dataKey: "title",
                accessor: (_) => _,
                cell: CellContent,
                sortable: true,
                cellStyle: { padding: 0, minWidth: "100%" },
              },
            ]}
            fillHeight
            renderEmpty={() => (
              <div className="fullWidth fullHeight flex center alignCenter">
                {isFetching ? "Recherche en cours..." : "Effectuez une recherche..."}
              </div>
            )}
            pagination={throttledSearchValue ? response : undefined}
            onChangePage={onChangePage}
            onChangeLimit={onChangeLimit}
          />
        )}
      </div>
    </>
  )
}
