import { Button } from "rsuite"

import api from "api"
import { FontIcon } from "components/FontIcon/FontIcon"
import { cellDate, cellString, cellRole, Table } from "components/Table"
import { User } from "types/User"

export function Users() {
  const { data: users = [] } = api.users.getAll()
  const [deleteUser] = api.users.delete()

  return (
    <Table<User>
      data={users}
      columns={[
        {
          grow: 3,
          title: "Nom",
          dataKey: "username",
          cell: cellString,
          sortable: true,
        },
        {
          grow: 3,
          title: "E-mail",
          dataKey: "email",
          cell: cellString,
          sortable: true,
        },
        {
          grow: 3,
          title: "Role",
          dataKey: "role",
          cell: cellRole,
          accessor: (_) => _,
          sortable: true,
        },
        {
          grow: 3,
          title: "Créé le",
          dataKey: "createdAt",
          cell: cellDate,
          sortable: true,
        },
        {
          grow: 3,
          title: "Dernière connexion",
          dataKey: "lastLogin",
          cell: cellDate,
          sortable: true,
        },
        {
          grow: 1,
          title: "Actions",
          dataKey: "id",
          accessor: (_) => _,
          cell: (value) => {
            return (
              <div className="flex end alignCenter fullWidth fullHeight ">
                <Button
                  appearance="primary"
                  className="marginRight10"
                  onClick={() => {
                    deleteUser(value.id)
                  }}
                >
                  <FontIcon icon="trash" />
                </Button>
              </div>
            )
          },
        },
      ]}
      fillHeight
      renderEmpty={() => (
        <div className="fullWidth fullHeight flex center alignCenter">Pas d'user disponible</div>
      )}
    />
  )
}
