import Cookies from "js-cookie"

import { authApi } from "api/auth"
import { mediasApi } from "api/medias"
import { User } from "types/User"

import { ApiRequest, setToken } from "./request"

import { COOKIE_DOMAIN } from "../../constants"

export class Auth extends ApiRequest {
  async login(plexToken: string): Promise<string> {
    const token = await this.api.post<any, string>("/login", { plexToken })
    if (token) {
      setToken(token)
      this.dispatch(authApi.actions.login(token))

      Cookies.set("ponpontv-token", token, { expires: 7, domain: COOKIE_DOMAIN, path: "/" })
    }
    return token
  }
  async logout() {
    setToken(null)
    this.dispatch(authApi.actions.logout())
    this.dispatch(mediasApi.actions.clear())

    Cookies.remove("ponpontv-token", { domain: COOKIE_DOMAIN })
  }
  async getMe(): Promise<User> {
    const me = await this.api.get<any, User>("/users/me")
    if (me) this.dispatch(authApi.actions.getMe(me))
    return me
  }
}
