import { Input, InputGroup } from "rsuite"

import { FontIcon } from "components/FontIcon/FontIcon"

import classes from "./SearchInput.module.scss"

type SearchInputProps = {
  value: string
  setValue: (value: string) => void
  onOpenPage: (page: "search" | "invite" | "users" | "requests") => void
  onSearch: (query: string) => void
}

export function SearchInput(props: SearchInputProps) {
  const { onOpenPage, onSearch, value, setValue } = props

  function onKeyPress(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key === "Enter") {
      onSearch(value)
      onOpenPage("search")
    }
  }

  return (
    <InputGroup inside className={classes.container}>
      <InputGroup.Addon>
        <FontIcon icon="magnifying-glass" />
      </InputGroup.Addon>
      <Input className={classes.input} onKeyUp={onKeyPress} value={value} onChange={setValue} />
      <InputGroup.Addon className={classes.menu} onMouseDown={() => onOpenPage("search")}>
        <FontIcon icon="sliders" />
      </InputGroup.Addon>
    </InputGroup>
  )
}
